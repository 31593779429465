import React from "react"
import { graphql, Link } from "gatsby"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"

const IndexPage = ( {data} ) => {
  const qry = data.allStrapiHomepage.edges[0].node;
  const qryNews = data.allStrapiNew.edges;

  return (
    <Layout>
    <SEO title="Willkommen" />
      <div className="header-nav">
        <ul>
          <li style={{ opacity: "0" }}>&nbsp;</li>
        </ul>
      </div>
    
      <Carousel images={ qry.images } />
      { qry.notification && <Reactmarkdown className="homepage-notification">{ qry.notification }</Reactmarkdown> }
      
      <div className="homepage-content">
        <div className="homepage-news">
          <h3>Neuigkeiten</h3>
          { qryNews.map(({ node }, index) => (
                <div key={index}>
                  <Link to={`/news/${node.slug}`}> { node.published_at } - { node.title }</Link>
                </div>
          ))}   
        </div>

        <div className="homepage-kontakt">
          <Reactmarkdown>{ qry.content }</Reactmarkdown>
        </div>
      </div>

  </Layout>
  )
}


export default IndexPage

export const query = graphql`
  query HomepageQuery {
    allStrapiHomepage {
      edges {
        node {
          content
          notification
          images {
            localFile {
              childImageSharp {
                fixed(height: 550) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          } 
        }
      }
    }
    allStrapiNew(
      sort: {order: DESC, fields: published_at}
    ) {
      edges {
        node {
          title
          slug
          published_at(formatString: "DD.MM.Y")
        }
      }
    }
  }
`